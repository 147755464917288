import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`
`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function RetailsPage() {
  return (
    <Layout pageName="industry">
       <SEO
        title="Most Reliable Technology Solutions for Retail outlets across USA"
        description="Be a retail leader with leading-edge retail technology solutions from WYN Technologies. Get a quote for POS, Digital Signage, cabling, and data management for your retail business."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Retails</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for the Retail Industry</GroceryTitle>
              <p>What will it take for American retailers to succeed? COVID-19 fundamentally changed the way that many people shop. Some of those changes and customer expectations won’t go away, even after the pandemic is long gone. The pressure is mounting for retailers to continue adopting technologies which will allow them to improve the customer experience. Not only do most shoppers expect to be able to browse and place orders online, they also expect there to be options for contactless payment, contactless pickup, home delivery, etc.</p>
              <p>But beyond this, there are also some essential business technologies that EVERY retail organization needs. Let <strong>WYN Technologies</strong> deliver the technology solutions your business needs to be able to run more smoothly, treat your employees and customers better, and ultimately GROW!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some Ways WYN Can Help You Transform Your Retail Business</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling, Including Options for Fiber</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>No matter what products you sell through your retail enterprise, having a good structured cabling system in place is really a requirement for any modern business. Need some remediation of your current data wiring? We can certainly help with that. In addition, <strong>WYN Technologies</strong> specializes in the design, installation, and implementation of new structured cable networks, racking, and data closets for any retail or commercial need.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having a good WiFi network is no longer something that’s considered optional for retail establishments like yours. For one thing, most scan guns and other business-specific devices are designed to connect to your network wirelessly. For another, your customers have come to expect easily accessible WiFi connectivity anytime they’re shopping with you. Does your existing network need some troubleshooting and upgrading? Need some additional access points installed, or just need to have a wireless network installed in the first place? We can make that happen for you at <strong>WYN</strong>.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Drive-Up Retail Service & Pickup</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>While most retail stores don’t have a drive-thru lane, adding the ability to offer drive-up pickup service can really boost the convenience factor for your customers – which is something they’ll truly appreciate. Outdoor digital signs, interactive touchscreens, and intercom systems are all helpful technologies to leverage in this regard; they can help you to create a seamless pickup experience for shoppers who’ve placed their order remotely. And not only does <strong>WYN Technologies</strong> install this equipment for organizations like yours, we can also help you to design the perfect drive-up solution to meet the needs of you and your retail customers!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Digital Signage Installation</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Speaking of digital signage, there are many great applications which can benefit your retail business aside from just a drive-up pickup service. Outdoor digital signs can be used to help attract passersby to your establishment in the first place. And there are lots of great uses for digital signs inside your store, too. They can really make your displays pop, and give you lots of creative merchandising options for merchandising and conveying important product information or selling points. You can also opt to go with interactive touchscreen signs which convey important product info, and which customers can engage with to help answer their own questions. The possibilities are almost endless! Connect with <strong>WYN</strong> today to explore which digital signage applications are right for your retail enterprise.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>While first impressions certainly matter, it’s often that all-important last impression which helps to decide whether a customer will ever return to shop with you again. And in a retail store, that last impression happens at checkout. Having the right point-of-sale system can really make all the difference, both for your employees and for your customers. <strong>WYN Technologies</strong> offers and installs top retail POS solutions for businesses of all sizes and scopes, and we’d be happy to partner with your retail business, too.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>                     
           <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Technology Project Management for New Stores, Remodels, and Moves, Adds, & Changes (MAC Services)</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>If all you need is a one-off installation of some specific business technology, we can take care of that. But <strong>WYN Technologies</strong> can also help you with so much more. Planning for a new store opening, a store remodel, or a location change? We provide expert move, add, & change (MAC) services, and we can serve as your technology installation consultant for most anything you may need for your retail enterprise. In addition to offering new equipment installations, we can also de-install any legacy equipment which may need moving or replacing. Just like our name says, we’re here to provide Whatever You Need!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Wish you had some reliable business technology IT support for your retail store or larger retail enterprise? We’ve got you, fam. <strong>WYN</strong> is here for you whenever you may need us, and we’re happy to provide service-after-the-sale contracts to help keep all of your installed equipment running smoothly. Running a retail business can be stressful enough on its own; the last thing you need to have to worry about is trying to maintain your essential technologies. Leave it to the pros at WYN!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Depot Repair Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Here’s something else to appreciate about partnering with <strong>WYN Technologies</strong>, too. We have a complete staging facility (including a secure data closet, video surveillance, and protected access) for getting your new equipment staged and ready to go, before we arrive to perform your installation. Got some older business technology equipment which may need replacing? Choose to take advantage of our depot repair services, where you may be able to find an effective refurbished equipment solution that will also save you some money. We’re here to help!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>VoIP Services for Retailers</h2>
                  <p>Having a good business phone and communications system in place is something that every modern retail operation really needs. Older analog systems or local PBX systems really don’t give you all the functionality, versatility, and cost-effectiveness that you want & need. It’s high time you upgraded to a VoIP-based phone system! Why is that? There are honestly several reasons.</p>
                  <p>To begin with, you’ll enjoy many features with VoIP which you simply can’t get with older phone technologies, including call recording, conference calls, least cost routing (LCR), unified communications, mobile connectivity, ease of scalability, and more. But here’s one of the most compelling reasons to switch – VoIP phone services are typically a good bit cheaper to operate than other standard monthly business phone plans. <strong>WYN Technologies</strong> has more than 20 VoIP provider options to offer, we we’re certain to be able to help you find the perfect VoIP solution for your retail business. Reach out to us, and let’s talk about it!</p>
               </MarkdownContent>
               <MarkdownContent>
                    <h2>Choose WYN Technologies for All Your Retail Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At <strong>WYN</strong>, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility – before we ever come out to install your new equipment.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services </strong>
                    <p><strong>WYN Technologies</strong> only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p><strong>WYN</strong> is ultimately here to help your business succeed. We understand how corporate finances work, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, <strong>WYN</strong> is committed to be here for you for the long run. We actually answer the phone whenever you call, and we’ll respond promptly to your needs in a timely manner, too.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started? Let’s Get Connected!</h2>
                <p>Need to plan some business technology upgrades for your retail shop, or even for multiple locations you manage? <strong>WYN Technologies</strong> is happy to come out and conduct a free site evaluation and consultation. Got some questions? Ready to get started? Connect with us today, and let WYN provide the best business technology solutions for your retail enterprise!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default RetailsPage
